// extracted by mini-css-extract-plugin
export var book = "IndiaWritingBee-module--book--oV1t3";
export var bookCover = "IndiaWritingBee-module--bookCover--1RUyZ";
export var hero = "IndiaWritingBee-module--hero--qU-wf";
export var heroH1 = "IndiaWritingBee-module--heroH1--n+FYA";
export var heroH2 = "IndiaWritingBee-module--heroH2--SAkLu";
export var heroImg = "IndiaWritingBee-module--heroImg--qSfP5";
export var heroMeta = "IndiaWritingBee-module--heroMeta--9kvzk";
export var iwbHilight = "IndiaWritingBee-module--iwbHilight--3Qs6G";
export var logoBlock = "IndiaWritingBee-module--logoBlock--DFhSR";
export var logoFreadom = "IndiaWritingBee-module--logoFreadom--9ov7q";
export var offer = "IndiaWritingBee-module--offer--n0+m7";
export var offerBtn = "IndiaWritingBee-module--offerBtn--xf9sV";
export var panel = "IndiaWritingBee-module--panel--1Kj6B";
export var prizes = "IndiaWritingBee-module--prizes--Jf4KE";
export var prizesSponsers = "IndiaWritingBee-module--prizesSponsers--J4jUt";
export var round = "IndiaWritingBee-module--round--LWId0";
export var roundNumber = "IndiaWritingBee-module--roundNumber---Nt3I";
export var roundsGrid = "IndiaWritingBee-module--roundsGrid--1rED9";
export var section = "IndiaWritingBee-module--section--rr0IJ";
export var tag = "IndiaWritingBee-module--tag--Nx5Te";
export var winner = "IndiaWritingBee-module--winner--i-M1v";