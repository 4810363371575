import React from "react";
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap';
import cx from 'classnames'

import Layout from '../../components/layout';
import Seo from "../../components/seo";

import * as styles from './IndiaWritingBee.module.scss'
import heroImg from '../../images/iwb/hero-image.jpg'
import bookImg from '../../images/iwb/book-cover.png'
import wonderHouseImg from '../../images/iwb/wonder-house.png'
import westlandBooksImg from '../../images/iwb/westland-books.png'
import redPandaImg from '../../images/iwb/red-panda.png'
import boomwriterImg from '../../images/iwb/boomwriter.png'
import overleafImg from '../../images/iwb/overleaf.png'
import freadomImg from '../../images/iwb/freadomLogo.png';

const roundsData = [
  {
    round: "1",
    date: "11-22 September 2023",
    desc: "Your students write a 3 chapter book started by Jeff Kinney.",
    payoff: "Each participant receives a printed book featuring their own work.*"
  },
  {
    round: "2",
    date: "9-13 October 2023",
    desc: "Round 1 winners write a short story following a prompt by a famous author.",
    payoff: "The best judged stories are collated into a printed book.*"
  },
  {
    round: "3",
    date: "20-24 November 2023",
    desc: "Round 2 winners write a short story following a prompt by a famous author.",
    payoff: "The best judged stories are collated into a printed book.*"
  },
]

const IndiaWritingBee = (props) => {
  const registerLink = "https://docs.google.com/forms/d/e/1FAIpQLScWY--RtKgkU09VusvfEzN2o0C7Jd-zpS6MpuEWy1Ik4jY4dQ/viewform?usp=sf_link"
  return (
    <Layout location={props.location} headerType="landing">
      <Seo
        title="National Writing Bee (India)"
        description="We are pleased to invite you to enrol your students in the National Writing Bee."
        image="/share/india-writing-bee.png"
        slug={props.location.pathname}
      />
      <Helmet>
        <title>The National Writing Bee (India)</title>
        <meta name="description" content="We are pleased to invite you to enrol your students in the National Writing Bee." />
      </Helmet>
      <section className={styles.hero}>
        <Container>
          <img src={heroImg} alt="The National Writing Bee featuring Jeff Kinney" className={styles.heroImg} />
          <h1 className={cx(styles.iwbHilight, styles.heroH1)}>The National Writing Bee</h1>
          <h2 className={cx(styles.heroH2)}>Featuring Jeff Kinney</h2>
          <p>Best Selling Author of Diary of a Wimpy Kid</p>
          <div className={styles.heroMeta}>
            <h3 className={styles.iwbHilight}>11 September 2023 - 24 November 2023</h3>
            <div className={styles.tag}>Grades 6-8</div>
          </div>
        </Container>
      </section>
      <div className={styles.offer}>
        <Container>
          <h2 className="headingHero">Early Bird Offer</h2>
          <p>Enrol your students before 16 August for our early bird rate:</p>
          <p className="heading">10 July 2023 - 16 August 2023 <br/>₹200/student</p>
          <p className="mb-1 headingSmall">Plus your school receives ₹50 for each student that enrols</p>
          <a href={registerLink} className={cx("white-btn", styles.offerBtn, "mb-1")} target="_blank" rel="noopener">Schools: Register Now</a>
          <p>After 16 July: <br/>₹250/student</p>
        </Container>
      </div>
      <Container>
        <div className={styles.book}>
          <img src={bookImg} alt="Book cover" className={styles.bookCover} />
          <p className="standout fontWeight-reg">We are pleased to invite you to enrol your students in the National Writing Bee.</p>
          <p className="standout fontWeight-reg">Every participant becomes a published author and receives a printed book.*</p>
        </div>
        <div className={styles.prizes}>
          <h3 className={cx(styles.iwbHilight, "headingHero")}>Prizes</h3>
          <p className="standout fontWeight-bold">Books worth Rs 3,00,000 will be awarded as prizes.</p>
          <p className="standout mb-2">The Writing Bee National Champion wins an all expense paid trip to the  UK and attends the Hay Festival.</p>
          <div className={styles.prizesSponsers}>
            <div className={styles.panel}>
              <p className="headingSmall">Prizes supported by:</p>
              <img src={wonderHouseImg} alt="Wonder House Logo" />
            </div>
            <div className={styles.panel}>
              <p className="headingSmall">Publishing Partners:</p>
              <div className={styles.logoBlock}>
                <img src={westlandBooksImg} alt="Westland Books Logo" />
                <img src={redPandaImg} alt="Red Panda Logo" />
              </div>
            </div>
            <div className={styles.panel}>
              <p className="headingSmall">Presented by:</p>
              <div className={styles.logoBlock}>
                <img src={boomwriterImg} alt="BoomWriter UK" />
                <img src={overleafImg} alt="Overleaf Books LLP" />
              </div>
            </div>

            <div className={styles.panel}>
              <p className="headingSmall">Promotional Partner:</p>
              <div className={styles.logoBlock}>
                <img className={styles.logoFreadom} src={freadomImg} alt="Wonder House Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <h3 className={cx(styles.iwbHilight, "headingHero")}>Competition Details</h3>
          <div className={cx(styles.panel, "mb-2")}>
            <h4>Registration</h4>
            <p>The competiton is open to students in Grade 6 to 8.</p>
            <p>There’s no limit to the number of students who can register from your school.</p>
            <p>Your school receives Rs 50 for each student that enrols from your school, towards facilitation of the competition.</p>
            <p className="mb-2">Register via our online form to reserve your place</p>
            <a href={registerLink} className="primary-btn btn-inline" target="_blank" rel="noopener">Schools: Register Now</a>
          </div>
          <h4>3 Rounds</h4>
          <p>The National Writing Bee uses a knock-out system split into 3 rounds. In each round writing will take place in school. </p>
          <p>Entires are submitted online using BoomWriter.</p>
          <p>Students will use BoomWriter’s peer voting to help decide the winners from each round</p>
          <div className={styles.roundsGrid}>
            { roundsData.map(d => (
              <div className={styles.round}>
                <span className={cx(styles.roundNumber, "headingHero", styles.iwbHilight)} title="Round 1">{d.round}</span>
                <p className="headingSmall mb-half">{d.date}</p>
                <p>{d.desc}</p>
                <p className="fontWeight-bold">{d.payoff}</p>
              </div>
            ))}
          </div>
          <div className={styles.winner}>
            <p className="heading mb-half">4 December 2023 <br/>Winner Announced</p>
            <p>The winner will receive books worth Rs 35000, a certificate and travels to the UK to attend the Hay Festival accompanied by a guardian (parent/teacher).</p>
          </div>
          <a href={registerLink} className="primary-btn btn-inline mb-2" target="_blank" rel="noopener">Schools: Register Now</a>
          <p className="smallText mb-2">* cost not included in registration – this will be an additional charge</p>
        </div>
      </Container>
    </Layout>
  )
}

export default IndiaWritingBee