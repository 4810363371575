/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.org/docs/gatsby-config/
 */

module.exports = {
  /* Your site config here */
  siteMetadata: {
    siteUrl:'https://boomwriter.co.uk',
    author: `@BoomWriterUK`,
    imageUrl: '/social/',
    description: 'BoomWriter UK (Previously GoWrite)'
  },
  plugins: [
    `gatsby-plugin-sass`, 
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-sitemap`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `BoomWriter UK (Previously GoWrite)`,
        short_name: `BoomWriter UK`,
        start_url: `/`,
        background_color: `#FFFFFF`,
        theme_color: `#FFFFFF`,
        display: `standalone`,
        icon: `src/images/meta/boom-icon.png`,
      },
    },
    { 
      resolve: `gatsby-plugin-env-variables`,
      options: {
        allowList: ["SITE_URL", "TEACHER_URL", "PUPIL_URL", "TEACHER_SIGNUP_WRITE_URL", "PARENT_STORE_URL", "WRITE_TO_RAISE_REGISTER_URL", "TEACHER_SIGNUP_LEAVERS_URL"]
      }
    },
  ]
}
